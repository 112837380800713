import React from "react";
import icon from "../../Images/Footer/mobile.png";
import icon1 from "../../Images/Footer/phone.png";
import icon2 from "../../Images/Footer/whatsapp.png";
import icon3 from "../../Images/Footer/email.png";
import icon4 from "../../Images/Footer/location.png";
import logo1 from "../../Images/Footer/facebook.png";
import logo2 from "../../Images/Footer/instagram.png";
import logo3 from "../../Images/Footer/twitter.png";
import logo4 from "../../Images/Footer/linkedin.png";
import bgimg from "../../Images/Buildings.png";

const Footer = () => {
  const info = [
    {
      img: icon,
      text: "051 723 6997",
    },
    {
      img: icon1,
      text: "+92 315 979 9999",
    },
    {
      img: icon2,
      text: "+92 331 597 7999",
    },
    {
      img: icon3,
      text: "info@flydaytravels.com",
    },
    {
      img: icon4,
      text: "G-33, Midcity Mall, Murree Road, Rawalpindi",
    },
  ];

  const logos = [
    {
      img: logo1,
      link: "https://www.facebook.com/profile.php?id=61559004181228&mibextid=ZbWKwL",
    },
    {
      img: logo2,
      link: "https://www.instagram.com/flydaytravels/",
    },
    {
      img: logo3,
      link: "https://twitter.com/flydaytravels",
    },
    {
      img: logo4,
      link: "https://www.linkedin.com/company/flyday-travel-tours/",
    },
  ];

  return (
    <div className="bg-slate-100">
      <div className="flex flex-col lg:flex-row justify-around px-5 lg:px-2 lg:py-2">
        {/* Contacts  */}
        <div className="flex flex-col justify-center lg:items-start items-center lg:pl-14">
          <h1 className="py-2 text-center text-lg font-bold">Contact Us</h1>
          {info.map((item) => (
            <div
              className="flex justify-start items-center gap-2 pb-2"
            >
              <div className="flex items-center justify-center bg-slate-300 rounded-full w-[40px] h-[40px]">
                <img src={item.img} className="h-[25px]" />
              </div>
              <div className="flex items-end  w-[170px] lg:w-[450px]">
              <h6 className="text-start text-[14px] lg:text-base ">{item.text}</h6>
                </div>
            </div>
          ))}
        </div>

        {/* About and Social Links */}
        <div className="lg:w-1/2 xl:w-3/4 flex flex-col justify-center items-center">
          {/* About */}
          <div className="mb-4">
            <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mt-5 text-center">
              About the company
            </h2>
            <p className="text-center mt-2 px-4 md:px-16">
              Flyday Travel & Tours - Rawalpindi's premier travel agency,
              offering tailored journeys, budget-friendly flights, and
              specialized Umrah packages. Our commitment to precision and care
              ensures a seamless travel experience. Trust us for top-notch
              services and let every journey be a celebration of exploration and
              joy.
            </p>
          </div>

          {/* Social Links */}
          <div>
            <h1 className="mb-2 text-xl md:text-2xl lg:text-3xl font-bold">
              Social Links
            </h1>
            <div className="flex justify-center items-center">
              {logos.map((item) => (
                <div className="flex justify-center items-center px-2">
                  <a
                    href={item.link}
                    target="_blank"
                  >
                    <div className="flex items-center justify-center px-1 bg-slate-300 border-1 rounded w-[40px] h-[40px] hover:bg-gray-300 hover:scale-125 transition-transform">
                      <img src={item.img} className="h-[25px]" />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={bgimg} alt="background" className="w-full" />
      </div>
    </div>
  );
};

export default Footer;
