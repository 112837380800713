// src/components/Loader.js
import React, { useEffect, useState } from 'react';
import airplane from '../Images/plane_icon.png';
import logo from '../Images/Flyday-logo.png'; // Path to company logo

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading time with a timeout
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds
  }, []);

  const airplaneStyle = {
    width: '70px',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    animation: 'fly 2s linear forwards',
  };

  const dotStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '8px',
    height: '8px',
    backgroundColor: '#0CA7DD',
    borderRadius: '50%',
    animation: 'gas 2s linear forwards',
  };

  const keyframes = `
    @keyframes fly {
      0% { left: 0%; }
      100% { left: calc(100% - 20px); } 
    }

    @keyframes gas {
      0% { left: 0%; opacity: 1; }
      25% { opacity: 0.75; }
      50% { opacity: 0.5; }
      100% { left: calc(100% - 20px); opacity: 0; }
    }
  `;

  return (
    <>
      {loading && (
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-white">
          <style>{keyframes}</style>
          {/* Responsive logo size based on screen width */}
          <img 
            src={logo} 
            alt="Logo" 
            className="mb-2 w-48 sm:w-60 md:w-72 lg:w-80 xl:w-96" 
          />
          
          {/* Container for the airplane and dots */}
          <div className="relative w-48 sm:w-60 md:w-72 lg:w-80 xl:w-96 h-10">
            <div style={{ ...dotStyle, animationDelay: '0s' }}></div>
            <div style={{ ...dotStyle, animationDelay: '0.3s' }}></div>
            <div style={{ ...dotStyle, animationDelay: '0.6s' }}></div>
            <div style={{ ...dotStyle, animationDelay: '0.9s' }}></div>
            <div style={{ ...dotStyle, animationDelay: '1.2s' }}></div>
            <div style={{ ...dotStyle, animationDelay: '1.5s' }}></div>
            <div style={{ ...dotStyle, animationDelay: '1.8s' }}></div>
            <div style={{ ...dotStyle, animationDelay: '2.1s' }}></div>

            {/* Airplane element */}
            <img src={airplane} alt="Airplane" style={airplaneStyle} />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
