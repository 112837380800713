import React from "react";
import destination1 from "../../Images/Destinations/hajj-umrah.jpg";
import destination2 from "../../Images/Destinations/dubai.jpg";
import destination3 from "../../Images/Destinations/turkey.jpg";
import destination4 from "../../Images/Destinations/malaysia.jpg";
import destination5 from "../../Images/Destinations/srilanka.jpg";
import destination6 from "../../Images/Destinations/baku.jpg";

const TopDestinations = () => {
  const destinations = [
    {
      img: destination1,
      country: "Saudi Arabia",
    },
    {
      img: destination2,
      country: "Dubai",
    },
    {
      img: destination3,
      country: "Turkey",
    },
    {
      img: destination4,
      country: "Malaysia",
    },
    {
      img: destination5,
      country: "Sri Lanka",
    },
    {
      img: destination6,
      country: "Baku, Azerbaijan",
    },
  ];

  return (
    <div className="relative p-6 py-10 bg-[#F1F5F9] mt-10"> {/* Removed the background image */}
      <h1 className="text-black text-center text-xl md:text-2xl lg:text-3xl font-bold mb-10">
        Top Destinations
      </h1>
      <div className="flex flex-wrap justify-center gap-6">
        {destinations.map((destination, index) => (
          <div key={index} className="relative w-[400px] h-[300px]">
            <img
              src={destination.img}
              alt={destination.country}
              className="w-full h-full object-cover rounded-lg"
            />
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-lg font-bold text-center py-2 rounded-b-lg">
              {destination.country}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopDestinations;
