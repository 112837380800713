import React, { useState } from "react";
// import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import logo from "../../Images/Flyday-logo.png";
// import Home from "./Home";
import About from "../../pages/About";
import Services from "../../pages/Services";
import Visa from "../../pages/Visa";
import Umrah from "../../pages/Umrah";
import Tours from "../../pages/Tours";
import Contact from "../../pages/Contact";
import { GiHamburgerMenu } from "react-icons/gi";
import Hamburger from "./Hamburger";

import { Outlet ,Link } from "react-router-dom";

const Navbar = () => {
  const heading = [
    {
      name: "HOME",
      url: "/",
    },
    {
      name: "ABOUT US",
      url: "/about-us",
    },
    {
      name: "SERVICES",
      url: "/services",
    },
    {
      name: "VISA",
      url: "/visa",
    },
    {
      name: "UMRAH",
      url: "/umrah",
    },
    {
      name: "TOURS",
      url: "/tours",
    },
    {
      name: "CONTACT US",
      url: "/contact",
    },
  ];

  const [show, setShow] = useState(false);
  return (
      <>
        {/* Navbar */}
        <div className="bg-white relative -z-5 flex lg:flex-row justify-between lg:justify-around lg:items-center items-center lg:gap-5 py-4 px-8">
          {/* Img - Logo Div  */}
          <div>
           <a href="https://flydaytravels.com/">           
            <img
              src={logo}
              alt="Flyday Travels & Tours Logo"
              style={{ width: "12rem", height: "4rem" }}
            />
           </a>
          </div>

          {/* Headings Div  */}
          <div className="lg:flex hidden gap-8 text-black orange pt-3 pb-3 ">
            {heading.map((item) => (
              <Link to={item.url} key={item.name}>
                <h1 className="font-bold hover:text-sky-700">{item.name}</h1>
              </Link>
            ))}
          </div>

          {/* Hamburger Menu  Div  */}
          <div className="lg:hidden flex">
          <GiHamburgerMenu  onClick={() => setShow(true)}
          className="text-blue"
          size={20}/>
          </div>
        </div>

        {show === true && <Hamburger setClick={setShow} />}
        <Outlet/>

      </>
  );
};

export default Navbar;

