import React, { useState } from "react";

const MainSection = () => {
  const x = [1, 2, 3];
  const [show, setShow] = useState(false);
  const handleToggle = (index) => {
    setShow({ ...show, [index]: !show[index] });
  };

  return (
    <div className="px-4 md:px-10 lg:px-20 xl:px-40 py-3 text-center">
      <h1 className="text-sky-700 text-base lg:text-lg xl:text-2xl">
        Welcome To{" "}
        <span className="hover:text-sky-900 font-bold">
          Flyday Travel & Tours
        </span>{" "}
        - The Best Travel Agency in Rawalpindi, Pakistan.
      </h1>
      <div className=" px-2 md:px-3 lg:px-5 xl:px-10 py-2">
        <p className="text-justify py-3 text-sm lg:text-base">
          Flyday Travel & Tours, based in Rawalpindi, Pakistan, offers a
          complete travel experience tailored to your needs. From visa
          consultancy and air tickets to travel insurance and adventure
          packages, we provide exceptional value and competitive rates. Our
          services ensure a seamless journey with budget-friendly flights,
          smooth transportation, and top-notch hotel reservations. Specializing
          in Umrah packages, we handle every detail from visa assistance to
          luxury accommodations near holy sites. Trust Flyday Travel & Tours for
          a memorable and well-managed travel experience.
        </p>
      </div>
    </div>
  );
};

export default MainSection;
