import React from "react";
import serv1 from "../../Images/Services-Icons/01.png";
import serv2 from "../../Images/Services-Icons/02.png";
import serv3 from "../../Images/Services-Icons/03.png";
import serv4 from "../../Images/Services-Icons/04.png";
import serv5 from "../../Images/Services-Icons/055.png";
import serv6 from "../../Images/Services-Icons/06.png";

const Services = () => {
  const cards = [
    {
      img: serv1,
      title: "Ticketing",
    },
    {
      img: serv2,
      title: "Visa Booking",
    },
    {
      img: serv3,
      title: "Hajj/Umrah",
    },
    {
      img: serv4,
      title: "Travel Insurance",
    },
    {
      img: serv5,
      title: "Travel Consultancy",
    },
    {
      img: serv6,
      title: "Tailor Packages",
    },
  ];
  return (
    <>
      <h1
        className="text-sky-700 py-4 text-sm md:text-base lg:text-lg xl:text-2xl text-center"
        style={{ fontSize: "24px", fontWeight: "bold" }}
      >
        Services We Offer
      </h1>
      <div className="w-[100%]">
        <div className="flex flex-wrap justify-center items-center gap-12 py-5">
          {cards.map((item) => (
            <div className="flex flex-col justify-center px-2 py-5 w-[80px] h-[80px] md:w-[150px] md:h-[150px]  hover:scale-125 transition-transform   ">
              <img src={item.img} className="w-[80px] h-[80px] md:w-[150px] md:h-[150px]" />
              <h2 className=" py-2 font-bold text-[#0C659D]">{item.title}</h2>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Services;
