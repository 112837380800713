import React from "react";
import icon1 from "../../Images/WhyUs/01.png";
import icon2 from "../../Images/WhyUs/02.png";
import icon3 from "../../Images/WhyUs/03.png";
import icon4 from "../../Images/WhyUs/04.png";
import icon5 from "../../Images/WhyUs/05.png";

const WhyUs = () => {
  const cards = [
    {
      img: icon1,
      title: "Best Services",
    },
    {
      img: icon2,
      title: "Best Prices",
    },
    {
      img: icon3,
      title: "Fast Booking",
    },
    {
      img: icon4,
      title: "Trustworthy",
    },
    {
      img: icon5,
      title: "Instant Support",
    },
  ];
  return (
    <div className="mt-3 mb-5">
      <h1
        className="text-black text-center py-4"
        style={{ fontWeight: "bold", fontSize: "26px" }}
      >
        Why Flyday Travel & Tours ?
      </h1>
      <div className=" w-[100%] ">
        <div className="flex justify-center items-center flex-wrap gap-10 lg:gap-24 py-5">
          {cards.map((item) => (
            <div className="flex flex-col justify-center px-2 py-5 w-[100px] h-[200px]">
              <img src={item.img} />
              <h3>{item.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
