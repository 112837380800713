import React from "react";
import Navbar from "../Components/Common/Navbar";
import banner from "../Images/About_Us_Banner.png";
import ceo from "../Images/CEO_Image-removebg-preview.png";
import Footer from "../Components/Common/Footer";

const About = () => {
  return (
    <div>
      {/* About Section */}
      <div>
        <img src={banner} alt="About Us Banner" className="w-full h-auto object-cover" />
        <h1 className="py-4 text-sky-700 text-center text-lg md:text-xl xl:text-3xl">
          About Us
        </h1>

        <div className="px-6 md:px-16 lg:px-32 xl:px-48 py-5 text-center">
          <p className="text-justify text-sm lg:text-base leading-relaxed">
            <b>Flyday Travel & Tours</b> is a premier travel agency in Rawalpindi,
            offering a full range of services including visa consultancy, air ticket bookings, hotel reservations, travel insurance, and customized adventure trips. Our dedication to personalized service ensures every journey is tailored to perfection.
            <br /><br />
            From value-driven deals to luxurious packages, we specialize in delivering unparalleled experiences. Our close relationships with top airlines, hotels, and service providers allow us to provide competitive rates without compromising on quality. We also take pride in our carefully curated Umrah packages that ensure a meaningful and spiritual pilgrimage experience.
          </p>
        </div>

        {/* CEO's Message */}
        <div>
          <h1 className="py-4 text-sky-700 text-center text-lg md:text-xl xl:text-3xl">
            CEO's Message
          </h1>
          <div className="flex flex-col md:flex-row justify-center items-center px-6 md:px-16 lg:px-32 xl:px-48 py-5">
            <div className="md:w-1/3 relative mb-5 md:mb-0">
              <img src={ceo} alt="CEO Profile" className="mx-auto md:mx-0 md:w-[80%]" />
              <div className="bg-sky-700 text-white px-5 py-3 rounded-lg text-center absolute bottom-8 left-0 right-0 mx-auto w-[220px] h-[70px]">
                <p className="text-lg">Asif Shahzad Qureshi</p>
                <p className="text-sm">CEO & Founder</p>
              </div>
            </div>

            <div className="md:w-2/3">
            <p class="text-justify mx-3 md:mx-10 py-3 px-3 md:px-10 text-sm lg:text-base tracking-normal">
                Dear Esteemed Travelers, <br /> <br />
                As we embark on a new chapter filled with boundless
                opportunities and thrilling adventures, I am honored to extend a
                heartfelt welcome to each of you. <br /> At Flyday Travel &
                Tours, we believe in the transformative power of travel to
                enrich lives, foster connections, and create lasting memories.
                It is with great pride and passion that our team dedicates
                itself to curating unforgettable experiences that exceed your
                expectations and inspire your wanderlust. <br /> In an
                ever-evolving world, we remain steadfast in our commitment to
                innovation, excellence, and sustainability. Whether you're
                exploring exotic landscapes, immersing yourself in vibrant
                cultures, or simply seeking moments of serenity, we are here to
                ensure your journey is seamless, safe, and truly extraordinary.{" "}
                <br /> As we journey forward together, we pledge to uphold the
                highest standards of integrity, transparency, and customer
                satisfaction. Your trust in us is the foundation of our success,
                and we are deeply grateful for the privilege to serve you.{" "}
                <br /> Thank you for choosing Flyday Travel & Tours as your
                companion in exploration. Here's to the adventures that await
                us! <br />
                <br />
                Warm regards <br />
                Asif Shahzad CEO, <br />
                Flyday Travel & Tours
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
